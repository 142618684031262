<template>
  <div class="iconLinks">
    <a
      class="iconLinks__iconLink"
      v-for="iconLink in iconLinks"
      :key="iconLink.title"
      :href="iconLink.url"
      :title="iconLink.title"
      target="_blank"
    >
      <img
        :src="
          getRequiredImagePath(
            iconLink.imageLocation,
            iconLink.title,
            iconLink.imageType
          )
        "
        :alt="`To Dust ${iconLink.title}`"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'IconLinks',
  props: {
    iconLinks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getRequiredImagePath(imageLocation, imageTitle, imageType) {
      return require(`@/assets/${imageLocation.toLowerCase()}/${imageTitle
        .toLowerCase()
        .replaceAll(' ', '-')}.${imageType}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.iconLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 500px;
  margin: auto;

  &__iconLink {
    margin: 10px;
  }
}

@media (min-width: 750px) {
  .iconLinks {
    &__iconLink {
      filter: brightness(70%);
    }

    &__iconLink:hover {
      filter: brightness(100%);
      transition: 0.3s;
    }
  }
}
</style>
