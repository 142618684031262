<template>
  <section id="secContact">
    <h2 id="hContact">{{ $t("headers.contact") }}</h2>
    <p id="pContactInfo" class="readFriendlyLayout">
      {{ $t("view.contact.text") }}
    </p>
    <SocialMedia />
    <ContactForm />
  </section>
</template>

<script>
import SocialMedia from '../components/Contact/SocialMedia.vue';
import ContactForm from '../components/Contact/ContactForm.vue';

export default {
  name: 'Contact',
  components: {
    SocialMedia,
    ContactForm,
  },
};
</script>
