<template>
  <section>
    <h2>{{ $t("headers.send-a-message") }}</h2>
    <form class="contact-form" v-on:submit.prevent="sendEmail()">
      <label id="formName" for="name" class="contact-form__label">
        {{ $t("view.contact.form.name") }}
      </label>
      <input
        type="text"
        id="name"
        name="name"
        v-model="contactForm.sender.name"
        required
        class="contact-form__input"
      />

      <label id="formEmail" for="email" class="contact-form__label">
        {{ $t("view.contact.form.email") }}
      </label>
      <input
        type="email"
        id="email"
        name="email"
        v-model="contactForm.sender.emailAddress"
        required
        class="contact-form__input"
      />

      <label id="formSubject" for="subject" class="contact-form__label">
        {{ $t("view.contact.form.subject") }}
      </label>
      <input
        type="text"
        id="subject"
        name="subject"
        v-model="contactForm.subject"
        required
        class="contact-form__input"
      />

      <label id="formMessage" for="message" class="contact-form__label">
        {{ $t("view.contact.form.message") }}
      </label>
      <textarea
        id="message"
        name="message"
        rows="3"
        v-model="contactForm.message"
        required
        class="contact-form__input"
      ></textarea>

      <button
        id="formSubmit"
        type="submit"
        name="submit"
        class="contact-form__button"
        title="Send message"
        :disabled="isSendingMessage"
      >
        {{ $t("view.contact.form.send") }}
      </button>
    </form>
    <div
      v-if="isSendMessageOperationCompleted"
      v-bind:class="[
        isMessageSentSuccessfully ? 'success' : 'failure',
        'snackbar'
      ]"
    >
      {{
        isMessageSentSuccessfully
          ? $t("view.contact.sendStatusSuccess")
          : $t("view.contact.sendStatusFailure")
      }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      contactForm: {
        sender: {
          name: '',
          emailAddress: '',
        },
        recipient: {
          organisation: 'ToDust',
          name: '',
        },
        subject: '',
        message: '',
      },
      response: null,
      messageSending: false,
      sendMessageOperationCompleted: false,
      messageSuccess: false,
    };
  },
  methods: {
    async sendEmail() {
      this.messageSending = true;
      this.sendMessageOperationCompleted = false;

      try {
        await this.$http
          .post(
            `${this.$store.getters.emailApiBaseUrl}/email/send`,
            this.contactForm,
          )
          .then((response) => (this.response = response));
      } catch (error) {
        // Do nothing
      }

      this.sendMessageOperationCompleted = true;
      this.messageSending = false;
    },
  },
  computed: {
    isSendingMessage() {
      return this.messageSending;
    },
    isSendMessageOperationCompleted() {
      return this.sendMessageOperationCompleted;
    },
    isMessageSentSuccessfully() {
      return this.response !== null && this.response.data === true;
    },
  },
};
</script>

<style scoped lang="scss">
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 10px;
  margin: auto auto 15px auto;

  border: 1px solid #c4c1b4;
  border-radius: 10px;

  &__label {
    font-size: 1.5em;
  }

  &__input {
    padding: 12px 20px;
    margin-bottom: 10px;
    transition: 0.25s;
  }

  &__button {
    padding: 5px;
    font-size: 1.5em;
    font-family: Immortal;
    align-content: center;
    cursor: pointer;

    &:disabled {
      background-color: grey;
    }
  }
}

.snackbar {
  width: 90%;
  max-width: 400px;
  margin: 15px auto 15px auto;
  padding: 15px 0 15px 0;
  border-radius: 5px;

  text-align: center;
  color: #c4c1b4;
}

.success {
  background-color: #008c39;
}

.failure {
  background-color: #8c0000;
}
</style>
