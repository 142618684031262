<template>
  <section>
    <h3 id="hSocialMedia">{{ $t("headers.social-media") }}</h3>
    <IconLinks :iconLinks="socialMediaSites" />
  </section>
</template>

<script>
import IconLinks from '../Common/IconLinks.vue';

export default {
  name: 'SocialMedia',
  components: {
    IconLinks,
  },
  data() {
    return {
      socialMediaSites: [
        {
          title: 'Facebook',
          url: 'https://www.facebook.com/Todustmetal',
          imageLocation: 'images/icons/social-media-icons',
          imageType: 'png',
        },
        {
          title: 'Instagram',
          url: 'https://www.instagram.com/todustsweden/',
          imageLocation: 'images/icons/social-media-icons',
          imageType: 'png',
        },
        {
          title: 'Twitter',
          url: 'https://twitter.com/todustsweden',
          imageLocation: 'images/icons/social-media-icons',
          imageType: 'png',
        },
        {
          title: 'Myspace',
          url: 'http://www.myspace.com/todustmetal',
          imageLocation: 'images/icons/social-media-icons',
          imageType: 'png',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
